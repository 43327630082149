<!-- Apply dynamic classes based on the theme using ngClass directive -->
<div  class="container-fluid">

    <!-- Container with box styles and fade-in-out animation -->
    <div class="box fade-in-out" style="margin-top: 100px;"> 
        <div class="w3-container mb-3" style="position: relative;">
            <div class="loader" ></div>
        </div>
        <div>
            <a [ngClass]="theme.getThemeClass()" class="hide-too-small-screen navbar-brand px-1" href="javascript:void(0);" data-bs-toggle="dropdown">
                <!-- Logo image with custom styles -->
                <img class="logo" src="../assets/logo-black.png">
            </a>
        </div>
    </div>
</div>

  

 
  

