import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public addItem(key: string, value: string): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): any {
    const value = localStorage.getItem(key);
    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (error) {
        console.error('Erro ao fazer o parse do valor JSON:', error);
        return null;
      }
    } else {
      return null;
    }
  }
  

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public clearItems() {
    localStorage.clear();
  }

}
