
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule, Router } from '@angular/router';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';


import { HttpClient, HttpBackend } from '@angular/common/http';

import { CommonModule } from '@angular/common';


import { environment } from './environments/environment';


import {
  MsalService,
  MsalModule,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SystemMessageComponent } from './system-message/system-message.component';
import { LoginComponent } from './login/login.component';
import { LoadingElementComponent } from './loading-element/loading-element.component';
import { MfaComponent } from './mfa/mfa.component';
import { FederatedLoginComponent } from './federated-login/federated-login.component';
import { SignOutComponent } from './sign-out/sign-out.component';



//Microsoft Guard Configurations
const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Popup,
  authRequest: {
    scopes: environment.saml.microsoft.muirwood.scopes,
  },
  loginFailedRoute: environment.saml.microsoft.muirwood.loginFailedRoute,
};

//Microsoft Interceptor Configurations
const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Popup,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  ]),
};

//Microsoft SAML/SSO Login Configurations
const msalConfig = {
  auth: {
    clientId: environment.saml.microsoft.muirwood.clientId,
    authority: environment.saml.microsoft.muirwood.authority,
    redirectUri: environment.saml.microsoft.muirwood.redirectUri,
    navigateToLoginRequestUrl:
      environment.saml.microsoft.muirwood.navigateToLoginRequestUrl,
  },
  cache: {
    cacheLocation: environment.saml.microsoft.muirwood.cacheLocation,
    storeAuthStateInCookie: environment.saml.microsoft.muirwood.storeAuthStateInCookie,
  },
};

//Microsoft Instance
const msalInstance: IPublicClientApplication = new PublicClientApplication(
  msalConfig
);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SystemMessageComponent,
    LoginComponent,
    LoadingElementComponent,
    MfaComponent,
    SignOutComponent
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatTableModule,
    HttpClientModule, // Import HttpClientModule for HttpClient
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend], // Specify HttpClient as a dependency
      },
    }),
    MsalModule.forRoot(msalInstance, guardConfig, msalInterceptorConfig),
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule
  ],
  providers: [
    TranslateService,
    MsalService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(http), '/assets/translation/', '.json');
}
