import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { JwtDecodeService } from './jwt-decode.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, map, throwError  } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public token: string = '';
  public redirect: string = '';
  constructor(
    private http: HttpClient,
    private decodeTokenService: JwtDecodeService) { }

  /**
   * Authenticates the user with the provided email.
   * @param email The email of the user to authenticate.
   * @returns An Observable emitting the redirect destination upon successful authentication or an error message.
  */

   async authenticateUser(email: string): Promise<string | boolean> {
    const url = `${environment.api.cdn}${environment.api.route.authenticate}`;
    const payload = { email: email };


      const response = await firstValueFrom(this.http.post<any>(url, payload));

      const redirectDestination = response.redirect;
      this.redirect = redirectDestination;
      this.token = response.token;
      const ulock  = parseInt(response.ulock);
     //console.log(ulock)

      if(ulock === 1){
        return false;
      }

      return redirectDestination;

  }

}
