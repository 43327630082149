
<section class="ribbon-fixed">
  <div class="preventClick">
    
  </div>
  <article *ngIf="systemMessageService.showRibbon && systemMessageService.selectedRibbon" #box class="alert in alert-dismissible " [ngClass]="['alert-' + systemMessageService.selectedRibbon.value]">
    <div class="ribbon-content"  >
      <strong>{{ systemMessageService.ribbonTitle | translate}}: </strong>
      <p>{{ systemMessageService.ribbonMessage | translate}}</p>
      <div class="ribbon-close" (click)="systemMessageService.closeRibbon()" aria-label="close" title="close">&times;</div>
    </div>
  </article>
</section>
