import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  list: any[] =[];
  constructor() {}




  //order array of list items based on the "number" attribute
  sortByNumberAscending(a: any, b: any): number {
    const numberA = parseInt(a.number);
    const numberB = parseInt(b.number);

    return numberA - numberB;
  }
}
