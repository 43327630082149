import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../service/cognito.service';
import { Router } from '@angular/router';
import { RedirectionService } from '../service/redirection.service';
import { LocalStorageService } from '../local-storage.service';
import { JwtDecodeService } from '../service/jwt-decode.service';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrl: './mfa.component.css'
})
export class MfaComponent implements OnInit {

  public mfaChecked: boolean = false;
  public MFACodeRequiredActive: boolean = false;
  public wrongMFACode: boolean = false;
  public nextTotpPage: boolean = false;
  public MFACodeSMS: string = '';
  public MFACodeTOTP: string = '';
  public MFACode: string = '';
  public signUpMFAActive: boolean = false;
  public delayTImer: number = 3000;
  public jwtFromLocalStorage: any;
  public loading: boolean = false;





  constructor(
    public cognitoService: CognitoService,
    private router: Router,
    private redirectionService: RedirectionService,
    private localStorageService: LocalStorageService,
    public decodeTokenService: JwtDecodeService,

  ) {};

  ngOnInit(): void {
    this.signUpMFAActive = true;

  }

  // Function called to go back to user-edit
  backToEditPage(){
    this.router.navigate(['/login'])
  }

  // This function must be adjusted according to the login portal specifications
  redirectToExternalUrl(url: string) {
    // this is an example
    const params = {
      param1: 'value1',
      param2: 'value2'
    };
    this.redirectionService.redirectToUrlWithParams(url, params);
  }



  // Function called in the sign-up MFA window when user click on the send button to get the QR code from cognito
  getMFARegisterCodeTOTP(){
    this.cognitoService.getQrCode();
  }

  // Function called with the submit button in the sign-up MFA window
  submitMFACodeClick(){
    // this.mfaChecked is the variable that UI use to know if the user want the SMS_MFA or TOTP_MFA then go to the proper function
    if(this.mfaChecked){
    //   // Call the function that will sign-up with SMS MFA code
      this.cognitoService.signUpMFACodeSMS(this.MFACodeSMS);
    }else{

      try {
        this.cognitoService.singUpMFACodeTOTP(this.MFACodeTOTP);

      } catch (error) {
          // Aqui você pode lidar com o erro da maneira que preferir
          console.error("Ocorreu um erro durante o signUpMFACodeTOTP:", error);
      }

    }
  }

  async redirectUsingLocalStorage() {

    this.jwtFromLocalStorage = this.localStorageService.getItem('jwtData')
    //jwtRedirect

    const redirect = this.localStorageService.getItem('jwtRedirect')
    // //console.log(redirect)

    // IF JWT IS NOT ALREADY STORED LOCALLY, RETRIEVE IT FROM LOCAL STORAGE
    // if(!this.jwtFromLocalStorage) {
    //   this.jwtFromLocalStorage = this.localStorageService.getItem('jwtData')
    // }



    // ADD DELAY BEFORE REDIRECTING
    await this.delay();

    // REDIRECT TO EXTERNAL URL

    if(await this.cognitoService.confirmValidUser()) {
      this.redirectToExternalUrl(redirect);
    } else {
      this.router.navigateByUrl('/login');

    }

    // this.jwtFromLocalStorage = this.localStorageService.getItem('jwtData')

    // // IF JWT IS NOT ALREADY STORED LOCALLY, RETRIEVE IT FROM LOCAL STORAGE
    // // if(!this.jwtFromLocalStorage) {
    // //   this.jwtFromLocalStorage = this.localStorageService.getItem('jwtData')
    // // }

    // // DECODE JWT AND GET REDIRECT DESTINATION
    // // const dataToken = this.decodeTokenService.decodeToken(this.jwtFromLocalStorage);

    // // GET REDIRECT FROM LOCAL STORAGE
    // const redirectDestination = this.localStorageService.getItem('jwtData');
    // // const dataToken = this.decodeTokenService.decodeToken(JSON.stringify(this.jwtFromLocalStorage));
    // // const redirectDestination = dataToken.redirect;


    // // ADD DELAY BEFORE REDIRECTING
    // await this.delay();

    // // REDIRECT TO EXTERNAL URL

    // if(await this.cognitoService.confirmValidUser()) {
    //   this.redirectToExternalUrl(redirectDestination);
    // } else {
    //   this.router.navigateByUrl('/login');

    // }
  }

  // Asynchronous method to introduce a delay
  async delay(): Promise<void> {
    // Returning a Promise that resolves after a certain delay
    return new Promise(resolve => {
        // Using setTimeout to introduce the delay
        setTimeout(() => {
            // Resolving the Promise after the delay
            resolve();
        }, this.delayTImer); // Delay time specified by this.delayTimer
    });
  }

  // Function called to sign-in when a user get MFA setting active
  // async MFASignIn(){
  //   // transferir para service de paginacao navegacao
  //   // this.loading = true;
  //   this.nextTotpPage = false;

  //   // transferir para servico
  //   this.loginActive = false;

  //   this.MFACodeRequiredActive = false;
  //   // Call the cognito service to sign-in
  //   await this.cognitoService.MFASignIn(this.user, this.MFACode).then((response) => {
  //     setTimeout(()=>{
  //       if(response !== 'no-MFA-sign-in'){

  //   //       // If return response from cognito is not an error the authentification have success so we get the cognito user
  //         this.cognitoService.getUser().then((response)=>{
  //           this.MFACodeRequiredActive = true;
  //           this.loading = false
  //           if(response){
  //   //           // If we get a user set the local strorage for the user and client he's in
  //             this.localStorageService.addItem('user',  response.attributes.given_name + ' ' + response.attributes.family_name);
  //             this.localStorageService.addItem('client',  response.attributes['custom:client_id']);
  //             if(this.localStorageService.getItem("user").length !== 0){
  //               this.loading = true;
  //               this.MFACodeRequiredActive = false;
  //               this.cognitoService.getKeys();

  //               setTimeout(()=>{
  //   //               //Route to dashboard upon success
  //                 this.router.navigate(['/dashboard']);},4000)

  //             }

  //           }
  //         }).catch((error)=>{
  //           console.error("Error in getting user : ", error);
  //           this.loading = false
  //           this.MFACodeRequiredActive = true;
  //         });
  //       }
  //       else {
  //         this.loading = false;
  //         this.MFACodeRequiredActive = true;
  //       }
  //     },
  //     1000
  //     )
  //   });

  // }



}
