import { stage } from './stage'
import { cdn } from './cdn'

export const environment = {
  production: false,
  cognito :{
    userPoolId : "eu-central-2_t6Q3YJ29l",
    userPoolWebClientId: "2ngpap786v125qol9876c0ms09",
    region: 'eu-central-2',
    identityPoolId: 'eu-central-2:92bb28e0-fe0d-47cc-8666-09a923df687f',
    sessionToken: sessionStorage.getItem("sessionToken") || "",
    accessKeyId: sessionStorage.getItem("accessKeyId") || "",
    secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
  },
  sns: {
    url: "",
  },
  api:{
    cdn: cdn,
    stage: stage,
    route: {
        example: 'example?action=changePassword',
        createUser: "",
        updateUser: "",
        authenticate: "/authenticate",
        redirectLink: 'https://dev.profilnova.com', // was 'dev.profilnova.com',
        authenticateUlockUser: ''
        // redirectLink: 'qas.profilnova.com', // for QAS ENVIRO
        // redirectLink: 'dev.profilnova.com',



    }
  },
  users:{

  },
  saml:{
    microsoft: {
     muirwood:{
      clientId: '8357310a-1762-4aa1-9706-90fe131add9f',
      authority: 'https://login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/',
      provider: 'login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0',
      // redirectUri: 'http://localhost:4200/login',
      redirectUri: 'https://muirwood.profilnova.com/login',

      navigateToLoginRequestUrl: true,
      loginFailedRoute: '/login',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      scopes: ['user.read', 'openid']
     },
     nova:{
      clientId: '8357310a-1762-4aa1-9706-90fe131add9f',
      authority: 'https://login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/',
      provider: 'login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0',
      redirectUri: 'http://localhost:4200/login',
      navigateToLoginRequestUrl: true,
      loginFailedRoute: '/login',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      scopes: ['user.read', 'openid']
     },

    }
  }
}
