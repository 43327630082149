import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CognitoService } from './cognito.service';
// import { error } from 'jquery';
import { environment } from '../environments/environment';
import { AuthenticationResult, Configuration, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SamlService {

  // private tenantConfigSubject = new BehaviorSubject<Configuration>(null);
  private tenantConfigSubject = new BehaviorSubject<Configuration | null>(null);

  private msalInstance: PublicClientApplication | null = null;



  userAuthenticatedSubject: Subject<boolean> = new Subject<boolean>();
  public _userAuthenticated: boolean = false;

  public cName: any;
  // public userAuthenticated: boolean = false;

  constructor(
    public microsoft: MsalService,
    public cognito: CognitoService
  ) {


    // this.microsoft.initialize().subscribe((response)=>{
    // })
  }

  get userAuthenticated(): boolean {
    return this._userAuthenticated;
  }

  set userAuthenticated(value: boolean) {
    this._userAuthenticated = value;
    this.userAuthenticatedSubject.next(value);
  }


  initializeMsal(tenantConfig: Configuration) {
    // If MSAL is already initialized and needs reconfiguration, instantiate a new PublicClientApplication
    if (this.msalInstance) {
      this.msalInstance = new PublicClientApplication(tenantConfig);
      this.microsoft.instance = this.msalInstance; // Update the msalService instance
    } else {
      this.msalInstance = new PublicClientApplication(tenantConfig);
      this.microsoft.instance = this.msalInstance; // Initialize the msalService instance
    }
    this.tenantConfigSubject.next(tenantConfig); // Notify subscribers about the configuration change
  }

  async signInWithMicrosoft() {

    let samlConfig: string;


    try {
      const loginRequest = {
        scopes: ['user.read'],
        prompt: 'select_account'
      };

      switch(true) {
        // test at localhost
        case this.cName === 'localhost':
          samlConfig = environment.saml.microsoft.muirwood.provider;
        break;

        case this.cName === 'muirwood':
          samlConfig = environment.saml.microsoft.muirwood.provider;
        break;


        case this.cName === 'nova':
          samlConfig = environment.saml.microsoft.nova.provider; // HAS TO BE CHECKED BEFORE LIVE
        break;

        default:
        break;
      }
      sessionStorage.clear();

      this.microsoft.loginPopup(loginRequest).subscribe((res)=>{

      // console.log(res)
      // console.log(res.account.idToken)
      // console.log(res.account.idTokenClaims?.exp)
      // console.log(res.account.name)
      // console.log(res.account.username)

      let expires = 0;
      let name = "";
      let idToken = res.account.idToken ?? "";

      if(res.account.idTokenClaims?.exp){
        expires = res.account.idTokenClaims.exp;
      }

      if(res.account.name){
        name = res.account.name;
      }


      // verificar como aplicar tela de loading enquanto esta sendo utilizado o saml service
      // implementar o redirecionamento apos pegar o username - fazer a call para php api e obter jwt

      // console.log(samlConfig);

      this.cognito.federatedSignIn(samlConfig, idToken,expires, name, res.account.username).then((response)=>{

        this.userAuthenticated = true;

        ////console.log(response);

        //TO DO implement successful login logic

        // redirectUsingLocalStorage - TO VERIFY TOMORROW
        this.cognito.getUser().then((res: any)=>{

        }).catch((error)=>{
          console.error("error",error)
        })


      }).catch((error)=>{
        console.error("Error with SAML sign in attempt: ", error);
      });
      });

      // The code here will continue executing, and the user will be redirected to the Microsoft login page.

    } catch (error) {
      console.error('Error triggering loginRedirect:', error);
    }
  }

  acquireTokenSilent(): Observable<AuthenticationResult> {
    const account = this.microsoft.instance.getActiveAccount();
    if (!account) {
      throw new Error('No active account! Ensure a user has logged in.');
    }

    const silentRequest: SilentRequest = {
      scopes: ["user.read"], // Substitua com os escopos necessários
      account: account
    };

    return from(this.microsoft.instance.acquireTokenSilent(silentRequest));
  }

  ssoSilent(): Observable<AuthenticationResult> {
    const silentRequest: SilentRequest = {
      scopes: ['user.read'],
    };
    return from(this.microsoft.instance.acquireTokenSilent(silentRequest));
  }

  getCurrentConfig() {
    return this.tenantConfigSubject.asObservable();
  }


  endLogin(){
    // this.microsoft.logout().subscribe();
    this.microsoft.instance.clearCache();
  }



}
