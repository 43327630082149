import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FederatedLoginComponent } from './federated-login/federated-login.component';
import { SignOutComponent } from './sign-out/sign-out.component';

const routes: Routes = [
  {path: 'signout', component: SignOutComponent },

  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  // {path: 'login', component: FederatedLoginComponent},
  {path: 'login/:action', component: LoginComponent},
  {path: 'login/:action/:return', component: LoginComponent},
  {path: 'login/:action/:return/:pass', component: LoginComponent},
  {path: 'signout/:error', component: SignOutComponent },
  {path: 'sign-up-mfa', component: LoginComponent },
  {path: 'change-password&redirect=me', component: LoginComponent },
  // {path: 'signout', component: LoginComponent },
  // {path: 'signout', component: SignOutComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
