<div class="container-fluid">
  <app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element>

    <!-- Sign-up whit MFA -->
<section
class="form-group fadeInDown text-center"
id="formContent"
[ngClass]="{ 'd-none': !signUpMFAActive }"
*ngIf="signUpMFAActive"
>
<article>
  <div class="fadeIn first">
    <img class="logo" src="../assets/logo-white.png" />
  </div>
  <h2 class="text-center text-dark">{{ "signUpMFA" | translate }}</h2>


  <form
    (ngSubmit)="submitMFACodeClick()"
    id="register-form"
    role="form"
    autocomplete="off"
    class="form"
    method="post"
  >
      <!-- Section for the SMS sign-up display ------ NOT IN USE FOR NOW -->
      <!-- <div *ngIf="mfaChecked">
        <p>{{ "smsSendTo" | translate }}</p>
        <div class="input-group">
          <input
            type="text"
            id="phone_number"
            [(ngModel)]="phone_number"
            class="form-control"
            name="phone_number"
            placeholder="{{ 'phoneNumber' | translate }}"
            required
            required
            minlength="1"
            [attr.readonly]="true"
            onfocus="this.blur()"
          />
        </div>
        <div class="input-group">
          <input
            type="text"
            id="codeMFA"
            [(ngModel)]="MFACodeSMS"
            class="form-control"
            name="codeMFA"
            placeholder="{{ 'enterMFACode' | translate }}"
            required
            required
            minlength="1"
          />
        </div>
        <nav class="divBtn">
          <a
            class="btnA"
            href="javascript:void(0)"
          >
            <div class="btn">
              <input
                class="inputBtn"
                name="recover-submit"
                value="{{ 'sendMFACode' | translate }}"
                type="submit"
              />
            </div>
          </a>
          <a class="btnA">
            <div class="btn">
              <input
                class="inputBtn"
                name="recover-submit"
                value="{{ 'loginForgotSubmit' | translate }}"
                type="submit"
              />
            </div>
          </a>
        </nav>
      </div> -->

      <!-- Section for the TOTP sign-up -->
      <!-- First page that display the Qr code -->
      <div *ngIf="!mfaChecked && !nextTotpPage">
        <img
          *ngIf="this.cognitoService.qrCodeImgActive"
          [src]="this.cognitoService.imgUrl"
          alt="Code Qr"
        />
        <!-- <img
        alt="Code Qr"
      /> -->

        <nav class="divBtn">
          <a class="btnA" (click)="backToEditPage()">
            <div class="btn">
              <input
                class="inputBtn"
                name="recover-submit"
                value="{{ 'userEditButtonLabelReturn' | translate }}"
                type="submit"
              />
            </div>
          </a>
          <a class="btnA">
            <div class="btn">
              <input
                class="inputBtn"
                (click)="nextTotpPage = true"
                name="recover-submit"
                value="{{ 'next' | translate }}"
                type="button"
              />
            </div>
          </a>
        </nav>
      </div>
      <!-- Second page that ask to enter the verification TOTP code -->
      <div *ngIf="!mfaChecked && nextTotpPage">
        <div class="input-group">
          <input #enterMFA
            type="text"
            id="codeMFA"
            [(ngModel)]="MFACodeTOTP"
            class="form-control"
            name="codeMFA"
            placeholder="{{ 'enterMFACode' | translate }}"
            required
            minlength="1"

          />
        </div>
        <nav class="divBtn">
          <a class="btnA">
            <div class="btn">
              <input
                class="inputBtn"
                (click)="nextTotpPage = false"
                name="recover-submit"
                value="{{ 'back' | translate }}"
                type="button"
              />
            </div>
          </a>
          <a class="btnA">
            <div class="btn">
              <input
                class="inputBtn"
                name="recover-submit"
                value="{{ 'loginForgotSubmit' | translate }}"
                type="submit"
              />
            </div>
          </a>
        </nav>
      </div>
  </form>
</article>
</section>
</div>
